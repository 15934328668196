import React, { useEffect, useState, useCallback } from 'react';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AutoloadTable } from '@silinfo/front-end-template';
import { NewButton } from '@silinfo/front-end-template/lib/esm/components/Button';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import EndpointProvider from '../../../../components/EndpointProvider';
import { default as courseList, default as courseListService } from '../../../../services/courseManagement/courseList';
import courseEnrollmentService from '../../../../services/studentServices/courseEnrollment';
import { create } from '../../../../store/notification';
import { Option } from '../../../../utils/AppConst';
import { TooltipRenderer } from '../../../../utils/AppConstElements';
import useAccessCheck from '../../../../utils/useAccessCheck';
import AddStudentButton from './AddStudentButton';
import DeleteCourseEnrollment from './DeleteCourseEnrollment';
import { tableColumns } from './fields';
import StudentRatingDialog from './StudentRatingDialog';
import { ICourseEnrollmentCentral } from './types';
import ExportButton, { ExportContext } from '../../../../components/Buttons/ExportButton';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { IInfo } from '../../../../utils/Interfaces/IInfo';
import { CourseEnrollmentFilter, ICourseView, initialCourseEnrollmentFilterState } from '../types';
import { RootState } from '../../../../store';
import courseBaseDataService from '../../../../services/courseManagement/courseBaseData';
import { endpoints } from '../../../../utils/endPoints';
import api from '../../../../api';

export interface CourseInfo {
    maxHeadCount: number;
    headCount: number;
    headCountWaiting: number;
}

export default function CourseEnrollments(props: { closed: boolean; readOnly?: boolean; course: ICourseView }) {
    const { user } = useSelector((state: RootState) => state.auth);
    const { courseId } = useParams() as { courseId: string };
    const [searchParams] = useSearchParams();
    const [tenant, setTenant] = useState<string>('');
    const courseTenant = searchParams.get('courseTenant');
    const [tableLoading, setTableLoading] = useState(false);
    const [isCourseEvaluable, setIsCourseEvaluable] = useState<boolean>(false);
    const [evaluationOptions, setEvaluationOptions] = useState<Option[]>([]);
    const [info, setInfo] = useState<IInfo<CourseEnrollmentFilter>>(initialCourseEnrollmentFilterState(courseId));
    const [courseInfo, setCourseInfo] = useState<CourseInfo>({
        maxHeadCount: 0,
        headCount: 0,
        headCountWaiting: 0,
    });
    const dispatch = useDispatch();
    const hasAccessToAddStudent = useAccessCheck(['course_manamagement_course_add_user']);
    const { closed, readOnly, course } = props;

    useEffect(() => {
        api.get(endpoints.currentTenantAll)
            .then((response) => {
                setTenant(response.data.id);
            })
            .catch((error) => {
                console.error('Error fetching current tenant name:', error);
            });
    }, []);

    const refreshCourseInfo = useCallback(() => {
        courseBaseDataService.get(+courseId, courseTenant).then((response) => {
            const headCounts = response.data?.data?.headCounts ?? [];
            const headCountArr = {
                maxHeadCount: headCounts[+tenant]?.maxHeadCount ?? null,
                headCount: headCounts[+tenant]?.headCount ?? null,
                headCountWaiting: headCounts[+tenant]?.headCountWaiting ?? null,
            };
            setCourseInfo(headCountArr);
        });
    }, [courseId, courseTenant, tenant]);

    useEffect(() => {
        if (!tenant) return;

        setTableLoading(true);
        axios
            .all([
                ...(courseTenant
                    ? [
                          courseList.validForEvaluating(courseId, courseTenant).then((res) => {
                              if (!res.data.valid) {
                                  dispatch(
                                      create({
                                          type: 'info',
                                          message: readOnly
                                              ? 'Minden eseménynek tartalmaznia kell legalább egy résztvevőt a jelenléti íven az értékelések megtekintéséhez!'
                                              : 'Minden eseménynek tartalmaznia kell legalább egy résztvevőt a jelenléti íven az értékeléshez!',
                                      }),
                                  );
                              }
                              setIsCourseEvaluable(res.data.valid);
                          }),
                      ]
                    : [
                          courseList.validForEvaluating(courseId, tenant).then((res) => {
                              if (!res.data.valid) {
                                  dispatch(
                                      create({
                                          type: 'info',
                                          message: readOnly
                                              ? 'Minden eseménynek tartalmaznia kell legalább egy résztvevőt a jelenléti íven az értékelések megtekintéséhez!'
                                              : 'Minden eseménynek tartalmaznia kell legalább egy résztvevőt a jelenléti íven az értékeléshez!',
                                      }),
                                  );
                              }
                              setIsCourseEvaluable(res.data.valid);
                          }),
                      ]),
                courseListService
                    .evaluationOptions(courseId, courseTenant)
                    .then((response) => setEvaluationOptions(response.data)),
                ...(hasAccessToAddStudent
                    ? [
                          courseBaseDataService.get(+courseId, courseTenant).then((response) => {
                              const headCounts = response.data?.data?.headCounts ?? [];
                              const headCountArr = {
                                  maxHeadCount: headCounts[+tenant]?.maxHeadCount ?? null,
                                  headCount: headCounts[+tenant]?.headCount ?? null,
                                  headCountWaiting: headCounts[+tenant]?.headCountWaiting ?? null,
                              };
                              setCourseInfo(headCountArr);
                          }),
                      ]
                    : []),
            ])
            .catch()
            .finally(() => {
                setTableLoading(false);
            });
    }, [readOnly, courseId, dispatch, hasAccessToAddStudent, courseTenant, tenant]);

    const OperationCellRenderer = ({
        row,
    }: GridRenderCellParams<ICourseEnrollmentCentral, ICourseEnrollmentCentral>) => {
        return (
            <Grid container spacing={1} flexDirection={'row'}>
                {!closed && !readOnly && (
                    <EndpointProvider endpoints={['course_manamagement_course_add_user']}>
                        <Grid item>
                            <DeleteCourseEnrollment
                                enrollmentToDelete={row}
                                setLoading={setTableLoading}
                                courseId={courseId}
                                courseTenant={courseTenant}
                                refreshCourseInfo={refreshCourseInfo}
                            />
                        </Grid>
                    </EndpointProvider>
                )}

                {(row.type === 'normal' || row.type === 'evaluated') && (
                    <EndpointProvider
                        endpoints={[
                            'course_manamagement_course_student_rating',
                            'course_manamagement_course_student_rating_show',
                        ]}
                    >
                        <StudentRatingDialog
                            studentId={row?.id}
                            evaluationStatus={row?.type}
                            courseId={courseId}
                            evaluationOptions={evaluationOptions}
                            isCourseEvaluable={isCourseEvaluable}
                            isClosed={closed}
                            readOnly={readOnly}
                            setLoading={setTableLoading}
                        />
                    </EndpointProvider>
                )}
            </Grid>
        );
    };
    const CampusCellRenderer = (currentRow: GridRenderCellParams<ICourseEnrollmentCentral, ICourseEnrollmentCentral>) =>
        TooltipRenderer(currentRow.row.campus);

    const TrainingProgramCellRenderer = (
        currentRow: GridRenderCellParams<ICourseEnrollmentCentral, ICourseEnrollmentCentral>,
    ) => TooltipRenderer(currentRow.row.trainingProgram);

    const StatusCellRenderer = (
        currentRow: GridRenderCellParams<ICourseEnrollmentCentral, ICourseEnrollmentCentral>,
    ) => {
        const colors = new Map([
            ['normal', 'success'],
            ['waiting_list', 'error'],
            ['evaluated', 'info'],
        ]);

        const labels = new Map([
            ['normal', 'Jelentkezett'],
            ['waiting_list', 'Várólista'],
            ['evaluated', 'Értékelt'],
        ]);

        const color = (colors.get(currentRow.row.type) || 'normal') as
            | 'default'
            | 'primary'
            | 'secondary'
            | 'error'
            | 'info'
            | 'success'
            | 'warning';

        const label = labels.get(currentRow.row.type);

        return <Chip color={color} label={label} />;
    };

    const exportContext: ExportContext = {
        initiateExport: courseEnrollmentService.initiateExport as (
            form: { format: string },
            filter: IForm,
        ) => Promise<AxiosResponse>,
        checkExport: courseEnrollmentService.checkExport,
        downloadExport: courseEnrollmentService.downloadExport,
        info,
        filter: { terminating: ['0'], courseId: courseId, courseTenant: courseTenant, courseName: course.courseName },
    };

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} justifyContent="flex-end">
                {!user.archive && (
                    <EndpointProvider endpoints={['course_manamagement_course_list']}>
                        <Grid item sx={{ marginRight: '15px' }}>
                            <ExportButton exportContext={exportContext} />
                        </Grid>
                    </EndpointProvider>
                )}
                <EndpointProvider endpoints={['course_manamagement_course_add_user']}>
                    <Grid item>
                        {!user.archive && !readOnly && !closed && (
                            <AddStudentButton
                                title="Hallgató hozzáadása"
                                submitBtnTitle="Hozzáadás"
                                setTableLoading={setTableLoading}
                                opener={<NewButton>Hallgató hozzáadása</NewButton>}
                                courseInfo={courseInfo}
                                onStudentAdded={refreshCourseInfo}
                            />
                        )}
                    </Grid>
                </EndpointProvider>
            </Grid>
            <AutoloadTable
                columns={tableColumns(
                    OperationCellRenderer,
                    CampusCellRenderer,
                    TrainingProgramCellRenderer,
                    StatusCellRenderer,
                )}
                filter={(form) => {
                    setInfo((prevState) => ({ ...prevState, filter: form }));
                    return courseEnrollmentService.getAllByCoursePaginated(form, courseTenant, courseId);
                }}
                responseKey="data"
                refresh={tableLoading}
            />
        </Grid>
    );
}
