/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { CancelButton, Checkbox, Link, Loading, TextField } from '@silinfo/front-end-template';
import axios from 'axios';
import { FastField, FormikProps } from 'formik';
import * as React from 'react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import EndpointProvider from '../../../../components/EndpointProvider';
import Autocomplete from '../../../../components/Form/Autocomplete';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import { ICourseEventLecture } from '../../../../interface/ICourseEventLecture';
import courseBaseDataService from '../../../../services/courseManagement/courseBaseData';
import courseListService from '../../../../services/courseManagement/courseList';
import campusesService from '../../../../services/masterData/campuses';
import { default as userService, default as usersService } from '../../../../services/masterData/users';
import { RootState } from '../../../../store';
import { Option, OrganiserOption, findLabelByValue } from '../../../../utils/AppConst';
import { CampusView } from '../../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import useAccessCheck from '../../../../utils/useAccessCheck';
import useEntityOptions from '../../../../utils/useEntityOptions';
import Address from '../../Address';
import GuestPerformerAutocomplete from '../GuestPerformerAutocomplete';
import OtherOrganiserAutocomplete from '../OtherOrganiserAutocomplete';
import { achievementOptions, courseCategoryOptions } from '../utils';
import BaseDataFormCourseCodeConfirmDialog from './BaseDataFormCourseCodeConfirmDialog';
import { CourseNameField, generateCourseCodeFromName } from './CourseNameField';
import ScoringRules from './ScoringRules';
import { ISemesterOption, TBaseData, TrefreshCodeVersion } from './types';
import {
    courseTypeServiceWithNewEmptyFilter,
    courseTypeServiceWithNewFilter,
    createUserOptions,
    getAchievedMinimumPoints,
    getAvaibleMaximumPoints,
    getCustomCoursePointPerEventPoints,
} from './utils';
import Questionnaire from './Questionnaire';
import Chip from '@mui/material/Chip';
import { theme } from '../../../../utils/theme';
import { AutocompleteGetTagProps } from '@mui/material/useAutocomplete';
import QuestionnaireDialog from './QuestionnaireDialog';

export default function BaseDataForm(
    props: FormikProps<TBaseData> & {
        instructorsIsDisabled: boolean;
        courseManagerIsDisabled: boolean;
        versionNames: Array<string>;
        setVersionNames: Dispatch<SetStateAction<never[]>>;
    },
) {
    const { versionNames, setVersionNames, values, setFieldValue, submitForm } = props;
    const id = useParams().courseId;
    const [isOutside, setIsOutside] = useState<boolean>(values.external);
    const [courseTypeShortName, setCourseTypeShortName] = useState<string>('');
    const [semesterCode, setSemesterCode] = useState<string>('');
    const [courseCodePost, setCourseCodePost] = useState<string>('01');
    const [semesterOptions, setSemesterOptions] = useState<ISemesterOption[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [campusOptions, setCampusOptions] = useState<Option[]>([]);
    const [organiserCampusOptions, setOrganiserCampusOptions] = useState<Option[]>([]);
    const [searchParams] = useSearchParams();
    const copyFrom = searchParams.get('copyfrom');
    const unfiltered =
        copyFrom || window.location.pathname.includes('edit') || window.location.pathname.includes('show');
    const courseTypeOptions = useEntityOptions(
        unfiltered ? courseTypeServiceWithNewEmptyFilter : courseTypeServiceWithNewFilter,
        'name',
        'id',
        'courseCategory',
        'shortName',
        'scoringType',
    )
        .filter((courseType) => courseType?.extraVal2 !== 'MGT')
        .sort((a, b) => a.label.localeCompare(b.label));

    const { courseId } = useParams() as { courseId: string };
    const [trainingProgramOptions, setTrainingProgramOptions] = useState<Option[]>([]);
    const [trainingProgramOrganiser, setTrainingProgramOrganiser] = useState<Option[]>([]);
    const [trainingProgramOrganiserData, setTrainingProgramOrganiserData] = useState<OrganiserOption[]>([]);
    const [eventLectures, setEventLectures] = useState<ICourseEventLecture[]>([]);
    const [eventAll, setEventAll] = useState<ICourseEventLecture[]>([]);
    const courseTypeCodeVersion = copyFrom ? values.courseType : null;
    const [avaibleMaximumPoints, setAvaibleMaximumPoints] = useState(0);
    const [achievedMinimumPoints, setAchievedMinimumPoints] = useState(0);
    const propValues = values;
    const hasAccessToEdit = useAccessCheck(['course_manamagement_course_edit']);
    const [trainingProgramNotEditable, setTrainingProgramNotEditable] = useState<boolean>(false);
    const { user } = useSelector((state: RootState) => state.auth);
    const isEdit = +courseId > 0;
    const [courseCodeConfirmDialogOpen, setCourseCodeConfirmDialogOpen] = useState<boolean>(false);
    const [questionnaireDialogOpen, setQuestionnaireDialogOpen] = useState<boolean>(false);
    const [courseCodeError, setCourseCodeError] = useState<string>('');
    const [tenantOptions, setTenantOptions] = useState<Option[]>([]);
    const [currentTenant, setCurrentTenant] = useState('');

    const changeHeadCount = (typeElement: React.ChangeEvent<HTMLInputElement>, tenant: string, fieldName: string) => {
        const { value } = typeElement.target;

        if (tenant) {
            setFieldValue(`headCounts.${tenant}.${fieldName}`, value);
        }
    };

    useEffect(() => {
        setAvaibleMaximumPoints(getAvaibleMaximumPoints(propValues, eventLectures, eventAll));
        setAchievedMinimumPoints(getAchievedMinimumPoints(propValues, eventLectures, eventAll));
        if (copyFrom) {
            setCourseCodePost(propValues.courseCodePost);
        }
    }, [eventLectures, eventAll, propValues, searchParams, copyFrom]);

    useEffect(() => {
        if (!id) {
            setLoading(true);
            usersService
                .getTrainingProgramByUserId(user.id)
                .then((res) => {
                    if (res.data.roleTrainingLeaderProgramId || res.data.roleTrainingProgramProgramId) {
                        if (res.data.roleTrainingLeaderProgramId) {
                            setFieldValue('trainingProgram', [res.data.roleTrainingLeaderProgramId]);
                        }
                        if (res.data.roleTrainingProgramProgramId) {
                            setFieldValue('trainingProgram', [res.data.roleTrainingProgramProgramId]);
                        }

                        setTrainingProgramNotEditable(true);
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [id, user.id, setFieldValue]);

    useEffect(() => {
        setLoading(true);

        const urlParams = new URLSearchParams(window.location.search);
        const copyFrom = urlParams.get('copyfrom');

        let effectiveId = id;
        if (!effectiveId && copyFrom) {
            effectiveId = copyFrom;
        }
        axios
            .all([
                courseListService.tenants(effectiveId).then((res) => {
                    setTenantOptions([...res.data.tenants]);
                    setCurrentTenant(res.data.currentTenant);
                    setFieldValue('tenants', res.data.initialValues);
                }),
                courseListService.semestersDetailed().then((res) => {
                    setSemesterOptions(res.data);
                    let value = '';
                    for (const element of res.data) {
                        if (element.active) {
                            value = element.value;
                            break;
                        }
                    }
                    if (!courseId && !copyFrom) {
                        setFieldValue('semester', value);
                    }
                }),
                courseListService.campuses().then((response) => {
                    const filtered = response.data.filter((campus: CampusView) => campus.active == true);
                    const unFiltered = response.data;
                    if (window.location.pathname.includes('new')) {
                        setCampusOptions(filtered);
                        setOrganiserCampusOptions(filtered);
                    } else if (!props.values.isTenant) {
                        campusesService.get(props.initialValues.campus).then((res) => {
                            const myCampus = res.data;
                            if (myCampus.active) {
                                setCampusOptions(filtered);
                            } else {
                                setCampusOptions(
                                    unFiltered.filter(
                                        (campus: CampusView) => campus.active == true || campus.value == myCampus.id,
                                    ),
                                );
                            }
                        });
                        campusesService.get(props.initialValues.organiserCampus).then((res) => {
                            const organiserCampus = res.data;
                            if (organiserCampus.active) {
                                setOrganiserCampusOptions(filtered);
                            } else {
                                setOrganiserCampusOptions(
                                    unFiltered.filter(
                                        (campus: CampusView) =>
                                            campus.active == true || campus.value == organiserCampus.id,
                                    ),
                                );
                            }
                        });
                    }
                }),
                userService
                    .trainingPrograms2(
                        (propValues.trainingProgram || []).map((elem: Option | number | string) =>
                            typeof elem === 'string' || typeof elem === 'number' ? elem : elem.value,
                        ),
                    )
                    .then((res) => setTrainingProgramOptions([...res.data])),
                userService
                    .trainingPrograms2(
                        (propValues.organiser || []).map((elem: OrganiserOption | number | string) =>
                            typeof elem === 'string' || typeof elem === 'number'
                                ? elem
                                : { value: elem.value, label: elem.label, duration: elem.duration },
                        ),
                    )
                    .then((res) => {
                        const special = res.data[res.data.length - 1];
                        setTrainingProgramOrganiser([...res.data.splice(-1)]);
                        setTrainingProgramOrganiser([...res.data, { value: special.value, label: 'Egyéb szervező' }]);
                        setTrainingProgramOrganiserData([...res.data.splice(-1)]);
                        setTrainingProgramOrganiserData([
                            ...res.data,
                            { value: special.value, label: 'Egyéb szervező', duration: '-1' },
                        ]);
                    }),
                effectiveId
                    ? courseBaseDataService.getEvents(+effectiveId, true).then((res) => setEventLectures(res.data))
                    : null,
                effectiveId ? courseBaseDataService.getEvents(+effectiveId).then((res) => setEventAll(res.data)) : null,
            ])
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId, id, setFieldValue, searchParams, props.initialValues.campus]);

    useEffect(() => {
        const semesterObj = semesterOptions.find((o) => o.value === values.semester);
        if (semesterObj && !id) {
            if (semesterObj.courseEnrollmentEnd && semesterObj.courseEnrollmentStart) {
                setFieldValue('enrollmentStartDate', semesterObj.courseEnrollmentStart);
                setFieldValue('enrollmentEndDate', semesterObj.courseEnrollmentEnd);
            } else {
                setFieldValue('enrollmentStartDate', semesterObj.start);
                setFieldValue('enrollmentEndDate', semesterObj.end);
            }
        }
    }, [id, values.semester, semesterOptions, setFieldValue]);

    const handleSubmit = (force?: boolean) => {
        if (
            !id &&
            !force &&
            courseCodePost !== '01' &&
            versionNames.find((e) => e === values.courseName) === undefined
        ) {
            // Nem a kiválasztott kurzuskód van, hanem valszeg kézzel beírt és így nem a 01-es verzió lesz
            setCourseCodeConfirmDialogOpen(true);
        } else {
            setCourseCodeConfirmDialogOpen(false);
            submitForm().finally(() => {
                setButtonLoading(false);
                setQuestionnaireDialogOpen(false);
            });
        }
    };

    const handleSave = () => {
        setFieldValue('redirect', 'edit');
        if (!values.questionnaire) {
            setQuestionnaireDialogOpen(true);
        } else {
            handleSaveSubmit();
        }
    };

    const handleSaveSubmit = () => {
        setButtonLoading(true);
        setQuestionnaireDialogOpen(false);
        handleSubmit();
    };

    const handleSaveAndBack = () => {
        setFieldValue(
            'redirect',
            sessionStorage.getItem('courseList')
                ? -1
                : (clientEndPoints.course_manamagement_course_list as unknown as number),
        );
        if (!values.questionnaire) {
            setQuestionnaireDialogOpen(true);
        } else {
            handleSaveSubmit();
        }
    };

    const handleCourseCodeChangeConfirmation = () => {
        handleSubmit(true);
    };

    const handleOtherOrganiserChange = React.useCallback(
        (values: string[]) => setFieldValue('otherOrganiser', values),
        [setFieldValue],
    );

    const handleGuestPerformerChange = React.useCallback(
        (values: string[]) => setFieldValue('guestInstructors', values),
        [setFieldValue],
    );

    const refreshCodeVersion: TrefreshCodeVersion = React.useCallback(
        (semester, checkCodeExist, courseCode, courseType) => {
            const params = {
                semester: semester || values.semester,
                courseType: copyFrom ? courseTypeCodeVersion : courseType || values.courseType,
                courseCode: courseCode || values.courseCode,
            };

            if (!(params.semester && params.courseCode && params.courseType)) {
                return;
            }

            return courseBaseDataService.getCourseCodeVersion(params).then((response) => {
                const setStr = response.data.version.toString().padStart(2, '0');
                setVersionNames(response.data?.names);
                if (checkCodeExist && setStr !== '01') {
                    // Amennyiben nem 01 az értéke, pedig generáltról van szó, nem pedig kiválasztottról, akkor újrageneráljuk
                    return refreshCodeVersion(semester, true, generateCourseCodeFromName(values.courseName, true));
                } else {
                    setCourseCodePost(setStr);
                    if (courseCode) {
                        setFieldValue('courseCode', courseCode);
                    }
                    return setStr;
                }
            });
        },
        [
            copyFrom,
            courseTypeCodeVersion,
            setFieldValue,
            setVersionNames,
            values.courseCode,
            values.courseName,
            values.courseType,
            values.semester,
        ],
    );

    useEffect(() => {
        const isShowPage = window.location.pathname.includes('/show');

        if (!values.readonly && isShowPage) {
            setFieldValue('readonly', true);
        }
    }, [values.readonly, setFieldValue]);

    if (loading) {
        return <Loading noHeight />;
    }

    const codeEnd: string = semesterCode ? '-' + semesterCode + '-' + courseCodePost : '';

    const isOtherOrganiser = (options: OrganiserOption[], formData: number[]) => {
        let l = false;

        formData.forEach((elem) => {
            const f = options.find((x) => elem == x.value);

            if (f) {
                if (f.duration == '-1') {
                    l = !l;
                }
            }
        });

        return l;
    };

    return (
        <>
            {FieldGenerator({
                name: 'courseType',
                label: 'Kurzustípus',
                type: 'select',
                options: courseTypeOptions,
                format: { xs: 12, sm: 6, md: 3 },
                fieldType: 'base',
                props: {
                    disabled: user.archive || loading || copyFrom || values.readonly || isEdit,
                    onChange: (typeElement: React.ChangeEvent<HTMLInputElement>) => {
                        const val = typeElement.target.value;
                        const courseType = courseTypeOptions.find((o) => o.value === val);
                        if (!id && !copyFrom) {
                            const extra = courseTypeOptions.find(
                                (e: { value: string; label: string; extraVal?: string; extraVal2?: string }) => {
                                    return val === e.value;
                                },
                            );

                            if (extra && extra?.extraVal2) {
                                setCourseTypeShortName(extra?.extraVal2);
                            } else {
                                setCourseTypeShortName('');
                            }
                        }
                        setFieldValue('courseType', val);
                        setFieldValue('courseCategory', courseType?.extraVal);
                        setFieldValue('selectedCourseCode', ''); // Ha újat választ ki az mindent felülír
                        setFieldValue('scoringType', courseType?.scoringType);
                        refreshCodeVersion(undefined, values.courseName !== values.selectedCourseName, undefined, val);
                    },
                    error: props.errors?.courseType,
                    helperText: props.errors?.courseType,
                },
                ...props,
            })}

            <CourseNameField
                id={id}
                refreshCodeVersion={refreshCodeVersion}
                courseTypeCodeVersion={courseTypeCodeVersion}
                courseTypeOptions={courseTypeOptions}
                setCourseTypeShortName={setCourseTypeShortName}
                {...props}
            />

            {FieldGenerator({
                name: 'courseNameEnglish',
                label: 'Kurzus megnevezése angol nyelven',
                format: { xs: 12, sm: 6, md: 4 },
                props: {
                    disabled: user.archive || values.readonly,
                    error: props.errors?.courseNameEnglish,
                    helperText: props.errors?.courseNameEnglish,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'organiser',
                label: 'Szervező',
                type: 'multiselect',
                fieldType: 'base',
                options: trainingProgramOrganiser,
                format: { xs: 12, sm: 3 },
                props: {
                    disabled: user.archive || values.readonly,
                    onChange: (_: unknown, value: (OrganiserOption | string | number)[]) => {
                        const organiserValue = value.map((elem) =>
                            typeof elem === 'string' || typeof elem === 'number' ? elem : elem.value,
                        );
                        setFieldValue('organiser', organiserValue);
                        if (!isOtherOrganiser(trainingProgramOrganiserData, values.organiser)) {
                            setFieldValue('otherOrganiser', []);
                        }
                    },
                    error: props.errors?.organiser,
                    helperText: props.errors?.organiser,
                },
                ...props,
            })}
            {isOtherOrganiser(trainingProgramOrganiserData, values.organiser) ? (
                <OtherOrganiserAutocomplete
                    value={values.otherOrganiser}
                    onChange={handleOtherOrganiserChange}
                    disabled={values.readonly || user.archive}
                    errorMessage={props.errors.otherOrganiser as string}
                />
            ) : (
                <></>
            )}
            {FieldGenerator({
                name: 'organiserCampus',
                label: 'Szervező campus',
                type: 'select',
                fieldType: 'base',
                options: organiserCampusOptions,
                format: { xs: 12, sm: 6, md: 3 },
                props: {
                    disabled: user.archive || values.readonly,
                    error: props.errors?.organiserCampus,
                    helperText: props.errors?.organiserCampus,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'trainingProgram',
                label: 'Kapcsolódó képzési program',
                type: 'multiselect',
                fieldType: 'base',
                options: trainingProgramOptions,
                format: { xs: 12, sm: 6, md: 3 },
                props: {
                    disabled: user.archive || trainingProgramNotEditable || values.readonly,
                    error: props.errors?.trainingProgram,
                    helperText: props.errors?.trainingProgram,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'semester',
                label: 'Szemeszter',
                type: 'select',
                options: semesterOptions,
                fieldType: 'base',
                format: { xs: 12, sm: 6 },
                props: {
                    error: props.errors?.semester,
                    helperText: props.errors?.semester,
                    disabled: user.archive || semesterOptions.length === 0 || values.readonly || isEdit,
                    onChange: (typeElement: React.ChangeEvent<HTMLInputElement>) => {
                        const val = typeElement.target.value;
                        setFieldValue('semester', val);
                        if (!id && !copyFrom) {
                            const extra = semesterOptions.find((e: Option & { extraVal?: string }) => {
                                return val === e.value;
                            });
                            if (extra && extra?.extraVal) {
                                setSemesterCode(extra?.extraVal);
                            } else {
                                setSemesterCode('');
                            }
                            refreshCodeVersion(val, values.courseName !== values.selectedCourseName);
                        }

                        if (copyFrom) {
                            const extra = semesterOptions.find((e: Option & { extraVal?: string }) => {
                                return val === e.value;
                            });
                            let localSemesterCode = '';

                            if (extra && extra?.extraVal) {
                                localSemesterCode = extra?.extraVal;
                            } else {
                                localSemesterCode = '';
                            }
                            refreshCodeVersion(val, values.courseName !== values.selectedCourseName)?.then((res) =>
                                setFieldValue(
                                    'courseCode',
                                    values.courseCode.slice(0, 8) + localSemesterCode + '-' + res,
                                ),
                            );
                        }
                    },
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'enrollmentStartDate',
                label: 'Kurzusfelvételi időszak kezdete',
                type: 'date',
                format: { xs: 12, sm: 3, md: 2 },
                props: {
                    disabled: user.archive || values.readonly,
                    error: props.errors?.enrollmentStartDate,
                    helperText: props.errors?.enrollmentStartDate,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'enrollmentEndDate',
                label: 'Kurzusfelvételi időszak vége',
                type: 'date',
                format: { xs: 12, sm: 3, md: 2 },
                props: {
                    disabled: user.archive || values.readonly,
                    error: props.errors?.enrollmentEndDate,
                    helperText: props.errors?.enrollmentEndDate,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'courseCode',
                label: 'Kurzuskód',
                format: { xs: 12, sm: 6, md: 2 },
                fieldType: 'base',
                ...props,
                props: {
                    error: ((props.errors?.courseName as string) || courseCodeError).length > 0,
                    helperText: (props.errors?.courseName as string) || courseCodeError,
                    disabled: user.archive || !!id || !!copyFrom || values.readonly,
                    inputProps:
                        !id && !copyFrom
                            ? {
                                  style: { 'width': '32px' },
                                  maxLength: 3,
                              }
                            : {},
                    value: values.courseCode?.toUpperCase(),
                    InputProps:
                        !id && !copyFrom
                            ? {
                                  startAdornment: (
                                      <InputAdornment position="end">{courseTypeShortName}-</InputAdornment>
                                  ),
                                  endAdornment: <InputAdornment position="start">{codeEnd}</InputAdornment>,
                              }
                            : {},
                    onChange: (typeElement: React.ChangeEvent<HTMLInputElement>) => {
                        const val = typeElement.target.value?.toUpperCase();
                        setFieldValue('courseCode', val?.toUpperCase());
                    },
                    onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value?.toUpperCase();
                        setFieldValue('courseCode', val?.toUpperCase());
                        if (!id) {
                            refreshCodeVersion();
                        }
                    },
                },
            })}
            <Grid item xs={12} sm={6}>
                <FastField as="div" name="courseManager">
                    <Autocomplete
                        getter={courseListService.userSearchCourseManagerOrSuperAdmin}
                        textFieldProps={{ label: 'Kurzuskezelő' }}
                        errorMessage={props.errors.courseManager as string}
                        autocompleteProps={{
                            disabled:
                                user.archive ||
                                !!(props.courseManagerIsDisabled || values.readonly || (values.isLockUsersField && id)),
                            value: (typeof values.courseManager === 'object' && values.courseManager?.label) || '',
                            onChange: (_, v) => setFieldValue('courseManager', v),
                        }}
                        createOptions={createUserOptions}
                    />
                </FastField>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FastField as="div" name="instructors">
                    <Autocomplete
                        getter={courseListService.userSearchTeacher}
                        textFieldProps={{ label: 'Oktató' }}
                        errorMessage={props.errors.instructors as string}
                        autocompleteProps={{
                            disabled:
                                user.archive ||
                                !!(props.instructorsIsDisabled || values.readonly || (values.isLockUsersField && id)),
                            value: values.instructors || [],
                            onChange: (_, v) => {
                                setFieldValue('instructors', v);
                            },
                            multiple: true,
                        }}
                        createOptions={createUserOptions}
                    />
                </FastField>
            </Grid>
            <GuestPerformerAutocomplete
                value={values.guestInstructors}
                onChange={handleGuestPerformerChange}
                disabled={user.archive || values.readonly}
                errorMessage={props.errors.guestInstructors as string}
            />
            {FieldGenerator({
                name: 'achievementType',
                label: 'Teljesítési típus',
                type: 'select',
                options: achievementOptions,
                format: { xs: 12, sm: 6 },
                props: {
                    disabled: user.archive || values.readonly,
                    error: props.errors?.achievementType,
                    helperText: props.errors?.achievementType,
                },
                ...props,
            })}

            {FieldGenerator({
                name: 'courseCategory',
                label: 'Kurzuskategória',
                type: 'select',
                format: { xs: 12, sm: 6 },
                options: [...courseCategoryOptions, { value: 'irrelevant', label: 'Nem releváns' }],
                props: {
                    disabled: user.archive || values.readonly,
                    inputProps: {
                        maxLength: 2,
                    },
                    error: props.errors?.courseCategory,
                    helperText: props.errors?.courseCategory,
                },
                ...props,
            })}

            {FieldGenerator({
                name: 'tenants',
                label: 'Tenant',
                type: 'multiselect',
                fieldType: 'base',
                format: { xs: 12, sm: 6 },
                options: tenantOptions,
                props: {
                    error: props.errors?.tenants,
                    helperText: props.errors?.tenants,
                    disabled: user.archive || values.readonly || location.pathname.includes('edit'),
                    disableClearable: true,
                    renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                        <li {...props} style={{ color: 'black' }}>
                            {option.label}
                        </li>
                    ),
                    getOptionLabel: (option: Option) => option.label || '',
                    renderTags: (options: Option[], getTagProps: AutocompleteGetTagProps) =>
                        options.map((option, index) => {
                            const value = option as unknown as string;
                            const isCurrentTenant = value === currentTenant;

                            return (
                                // getTagProps ad key-t
                                // eslint-disable-next-line react/jsx-key
                                <Chip
                                    size="small"
                                    disabled={isCurrentTenant}
                                    sx={{
                                        backgroundColor: isCurrentTenant ? '#E3E3E3' : theme.palette.secondary.main,
                                        color: isCurrentTenant ? 'black' : theme.palette.secondary.contrastText,
                                        '& .MuiChip-deleteIcon': {
                                            color: theme.palette.primary.main,
                                            display: isCurrentTenant ? 'none' : 'inherit',
                                        },
                                    }}
                                    label={findLabelByValue(value, tenantOptions)}
                                    {...getTagProps({ index })}
                                />
                            );
                        }),
                },
                ...props,
            })}

            {props.values.tenants.map((tenant) => {
                const maxAbsenceName = `headCounts.${tenant}.maxAbsence`;
                const minHeadCountName = `headCounts.${tenant}.minHeadCount`;
                const maxHeadCountName = `headCounts.${tenant}.maxHeadCount`;

                return (
                    <>
                        <Typography style={{ padding: '16px 0 0 16px', fontWeight: 700, fontSize: '20px' }}>
                            {findLabelByValue(tenant, tenantOptions) + ' létszám adatok'}
                        </Typography>
                        <Grid item xs={12} container spacing={2} alignItems="flex-start">
                            {FieldGenerator({
                                name: maxAbsenceName,
                                label: 'Maximum hiányzás',
                                fieldType: 'base',
                                format: { xs: 12, sm: 6, md: 3, lg: 2 },
                                props: {
                                    disabled: user.archive || values.readonly,
                                    inputProps: {
                                        maxLength: 2,
                                    },
                                    error: (props.errors as any)?.[maxAbsenceName],
                                    helperText: (props.errors as any)?.[maxAbsenceName],
                                    value: props.values.headCounts?.[tenant]?.maxAbsence ?? '',
                                    onChange: (typeElement: React.ChangeEvent<HTMLInputElement>) =>
                                        changeHeadCount(typeElement, tenant, 'maxAbsence'),
                                },
                                ...props,
                            })}
                            {FieldGenerator({
                                fieldType: 'base',
                                name: minHeadCountName,
                                label: 'Minimum létszám',
                                props: {
                                    disabled: user.archive || values.readonly,
                                    inputProps: {
                                        maxLength: 3,
                                    },
                                    error: (props.errors as any)?.[minHeadCountName],
                                    helperText: (props.errors as any)?.[minHeadCountName],
                                    value: props.values.headCounts?.[tenant]?.minHeadCount || '',
                                    onChange: (typeElement: React.ChangeEvent<HTMLInputElement>) =>
                                        changeHeadCount(typeElement, tenant, 'minHeadCount'),
                                },
                                format: { xs: 12, sm: 6, md: 3, lg: 2 },
                                ...props,
                            })}
                            {FieldGenerator({
                                name: maxHeadCountName,
                                label: 'Maximum létszám',
                                fieldType: 'base',
                                props: {
                                    disabled: user.archive || values.readonly,
                                    inputProps: {
                                        maxLength: 3,
                                    },
                                    error: (props.errors as any)?.[maxHeadCountName],
                                    helperText: (props.errors as any)?.[maxHeadCountName],
                                    value: props.values.headCounts?.[tenant]?.maxHeadCount || '',
                                    onChange: (typeElement: React.ChangeEvent<HTMLInputElement>) =>
                                        changeHeadCount(typeElement, tenant, 'maxHeadCount'),
                                },
                                format: { xs: 12, sm: 6, md: 3, lg: 2 },
                                ...props,
                            })}
                        </Grid>
                    </>
                );
            })}

            <Questionnaire />
            <Grid item xs={12}>
                <Typography variant="h6" style={{ marginTop: '8px' }}>
                    Helyszín adatok
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
                <Checkbox
                    checkbox={{
                        disabled: user.archive || values.readonly,
                        checked: isOutside,
                        onChange: () => {
                            setIsOutside(!isOutside);
                            setFieldValue('external', !isOutside ? '1' : '0');
                        },
                    }}
                    label={'Külső helyszín megadása'}
                />
            </Grid>

            <Address
                isOutside={isOutside}
                campusOptions={campusOptions}
                readonly={user.archive || values.closed || values.readonly}
            />

            <ScoringRules
                courseId={courseId}
                eventLectures={eventLectures}
                achievedMinimumPoints={achievedMinimumPoints}
                avaibleMaximumPoints={avaibleMaximumPoints}
                eventCount={eventAll.length}
                customCoursePointPerEventPoints={getCustomCoursePointPerEventPoints(propValues, eventAll)}
                customCoursePointPerEventPointsMin={getCustomCoursePointPerEventPoints(propValues, eventAll, true)}
                isArchiveUser={user.archive}
            />

            {props.errors?.maxPointAvailable && (
                <Grid item xs={12} style={{ color: '#ff6961' }}>
                    {props.errors?.maxPointAvailable?.toString()}
                </Grid>
            )}

            {courseId && (
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={values.headCount || 'Nincs adat'}
                        label="Aktuális létszám"
                        name="headCount"
                        disabled={true}
                    />
                </Grid>
            )}

            <Grid item container spacing={2} justifyContent="flex-end">
                <EndpointProvider endpoints={['course_manamagement_course_edit']}>
                    <Grid item>
                        <LoadingButton
                            loading={buttonLoading}
                            onClick={handleSave}
                            variant="contained"
                            disabled={user.archive || values.readonly || buttonLoading}
                        >
                            Mentés
                        </LoadingButton>
                    </Grid>
                </EndpointProvider>
                {!user.archive ? (
                    <Grid item>
                        <LoadingButton
                            loading={buttonLoading}
                            onClick={handleSaveAndBack}
                            variant="contained"
                            disabled={values.readonly || buttonLoading}
                        >
                            {hasAccessToEdit ? 'Mentés és vissza a listaoldalra' : 'Mentés'}
                        </LoadingButton>
                    </Grid>
                ) : (
                    <></>
                )}
                <Grid item>
                    <Link
                        to={
                            sessionStorage.getItem('courseList')
                                ? -1
                                : (clientEndPoints.course_manamagement_course_list as unknown as number)
                        }
                    >
                        <CancelButton>Mégse</CancelButton>
                    </Link>
                </Grid>
            </Grid>
            <QuestionnaireDialog
                questionnaireDialogOpen={questionnaireDialogOpen}
                setQuestionnaireDialogOpen={setQuestionnaireDialogOpen}
                handleSubmit={handleSaveSubmit}
            />
            <BaseDataFormCourseCodeConfirmDialog
                courseCodeConfirmDialogOpen={courseCodeConfirmDialogOpen}
                setCourseCodeConfirmDialogOpen={setCourseCodeConfirmDialogOpen}
                setCourseCodeError={setCourseCodeError}
                isCopy={!!copyFrom}
                handleSubmit={handleCourseCodeChangeConfirmation}
            />
        </>
    );
}
