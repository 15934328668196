import Grid from '@mui/material/Grid';
import { FormikListPage, Link } from '@silinfo/front-end-template';
import ExportButton from '../../../components/Buttons/ExportButton';
import SemesterSelector from '../../../components/SemesterSelector';
import { SemesterContextProvider } from '../../../components/SemesterSelector/SemesterContextProvider';
import courseEvaluationReportService from '../../../services/courseManagement/courseEvaluationReport';
import { Option } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { filterFields, filterFieldsStudents } from './fields';
import { TabContentProps } from './types';
import {
    columns,
    exportContext,
    getQuestionnaireStatusTranslation,
    header,
    ICourseEvaluationReportStudent,
    ICourseEvaluationReportStudentQuestionnaire,
} from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';
import API from '../../../api';
import { create } from '../../../store/notification';
import { useState } from 'react';
import MCCLoading from '../../../components/MCCLoading';
import { useTenant } from '../../../components/TenantContext';
import { GridRowClassNameParams, GridValueGetterParams } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import RiportsStyle from '../../Admission/Riport/Riports.module.scss';
import { clientEndPoints } from '../../../utils/clientEndPoints';

export default function TabContent({ view }: TabContentProps) {
    const { user } = useSelector((state: RootState) => state.auth);
    const [semester, setSemester] = useState<Option | null>(null);
    const [formikFilter, setFormikFilter] = useState({});
    const [syncing, setSyncing] = useState(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const filterFunc = async (filter: IForm) => {
        const convertedFilter: IForm = {
            ...filter,
            ...(filter['order[__tree_data_group__]']
                ? { ['order[student]']: filter['order[__tree_data_group__]'] }
                : {}),
        };

        setFormikFilter(convertedFilter);

        if (view === 'course') {
            return courseEvaluationReportService.filterCourses(semester?.value || '')(convertedFilter);
        }

        const { data: rawData, ...rest } = await courseEvaluationReportService.filterStudents(semester?.value || '')(
            convertedFilter,
        );

        const { data, ...rest2 } = rawData;

        const transformedData = data.flatMap((student: ICourseEvaluationReportStudent) => {
            const studentNode = {
                id: student.id,
                studentId: student.id,
                tarStudentId: student.tarStudentId,
                student: student.student,
                campus: student.campus,
                trainingProgram: student.trainingProgram,
                waiting: student.waiting,
                filled: student.filled,
                unfilled: student.unfilled,
                declined: student.declined,
                expired: student.expired,
                notAnonimFillsCount: student.notAnonimFillsCount,
                type: 'student', // Hallgató és kérdőív sorok megkülönböztetése
            };

            // Kérdőívek létrehozása és összekapcsolása a hallgatóval
            const questionnaireNodes = student.questionnaires.map((q: ICourseEvaluationReportStudentQuestionnaire) => ({
                id: q.id,
                courseId: q.courseId,
                courseExternalId: q.courseExternalId,
                studentId: student.id, // Kérdőív összekapcsolása a hallgatóval
                anonymous: q.anonymous,
                deadlineDate: q.deadlineDate,
                questionnaireStatus: q.questionnaireStatus,
                student: q.courseName, // Át kell konvertálni a kulcsot
                campus: q.anonymous ? 'Anonim' : 'Nem anonim',
                filled: q.deadlineDate
                    ? new Date(q.deadlineDate).toLocaleDateString('hu-HU', { timeZone: 'UTC' })
                    : '-',
                trainingProgram: getQuestionnaireStatusTranslation(q.questionnaireStatus),
                type: 'questionnaire',
            }));

            // Visszaadja a hallgatói csomópontot és annak gyerekeit (questionnaires)
            return [studentNode, ...questionnaireNodes];
        });

        return {
            ...rest,
            data: {
                ...rest2,
                data: transformedData,
            },
        };
    };

    const dispatch = useDispatch();

    const handleSync = () => {
        setSyncing(true);
        API.get(
            endpoints.courseManagement.courseEvaluationReport.studentsSync.replace(
                '{semesterId}',
                semester?.value ?? '',
            ),
        )
            .then(() => {
                dispatch(create({ type: 'success', message: 'Sikeres CENTRAL sync!' }));
                setRefresh(!refresh);
            })
            .catch((error) => {
                console.error(error);
                dispatch(create({ type: 'error', message: 'Sikertelen CENTRAL sync!' }));
            })
            .finally(() => {
                setSyncing(false);
            });
    };

    return (
        <>
            {syncing && <MCCLoading />}
            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs>
                        <SemesterSelector
                            semesterState={[semester, setSemester]}
                            url={endpoints.selfEvaluation.report.semesters}
                            defaultActive
                        />
                    </Grid>
                    {view === 'student' && (
                        <Grid item marginTop="4px" marginLeft="12px">
                            <Tooltip title="Kérdőív értekek szinkronizálása az adott szemeszterhez">
                                <IconButton onClick={handleSync} disabled={syncing}>
                                    <CachedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SemesterContextProvider semester={semester?.value || ''}>
                    <FormikListPage
                        header={{ ...headerWithTenant, breadcrumbs: {} }}
                        autoload
                        service={{
                            filter: filterFunc,
                        }}
                        defaultSort={view === 'student' ? { __tree_data_group__: 'asc' } : {}}
                        customButtons={
                            <>
                                {!user.archive && (
                                    <Grid item xs={12} textAlign="right">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                            <ExportButton
                                                exportContext={exportContext(formikFilter, semester?.value || '', view)}
                                            />
                                            <ExportButton
                                                title="Kurzusteljesítési adatok"
                                                exportContext={exportContext(
                                                    formikFilter,
                                                    semester?.value || '',
                                                    view,
                                                    'courseCompletion',
                                                )}
                                            />
                                        </div>
                                    </Grid>
                                )}
                            </>
                        }
                        columns={columns(view, user.archive)}
                        responseKey="data"
                        filter={{
                            fields:
                                view === 'course'
                                    ? filterFields(semester?.value || '', view)
                                    : filterFieldsStudents(semester?.value || '', view),
                        }}
                        refresh={semester?.value || ''}
                        tableProps={
                            view === 'student'
                                ? {
                                      className: 'treeDataStyle',
                                      treeData: true,
                                      getTreeDataPath: (row) => {
                                          if (row.courseId) {
                                              return [row.studentId, row.courseId];
                                          }
                                          return [row.id];
                                      },
                                      groupingColDef: {
                                          sortable: true,
                                          headerName: 'Hallgató',
                                          valueGetter: (params: GridValueGetterParams) =>
                                              params.row.type === 'student' ? (
                                                  <Typography
                                                      variant="inherit"
                                                      sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                  >
                                                      {params.row.student}
                                                  </Typography>
                                              ) : (
                                                  <Link
                                                      to={clientEndPoints.course_manamagement_course_show.replace(
                                                          ':courseId',
                                                          params.row.courseExternalId,
                                                      )}
                                                      target="_blank"
                                                  >
                                                      <Typography
                                                          variant="inherit"
                                                          sx={{
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis',
                                                              cursor: 'pointer',
                                                              color: 'blue',
                                                              '&:hover': {
                                                                  color: 'darkblue',
                                                              },
                                                          }}
                                                      >
                                                          {params.row.student}
                                                      </Typography>
                                                  </Link>
                                              ),
                                          hideDescendantCount: true,
                                          width: 300,
                                      },
                                      columnBuffer: columns(view, user.archive).length,
                                      disableSelectionOnClick: true,
                                      initialState: {
                                          sorting: {
                                              sortModel: [{ field: 'student', sort: 'asc' as const }],
                                          },
                                      },
                                      getRowClassName: (params: GridRowClassNameParams) => {
                                          if (params.row.courseId) {
                                              return RiportsStyle.admissionRow;
                                          }
                                          return RiportsStyle.userRow;
                                      },
                                  }
                                : {}
                        }
                    />
                </SemesterContextProvider>
            </Grid>
        </>
    );
}
